import axios from "axios";
import React, { useState, useEffect, useRef } from "react";

import Modal from "react-modal";
import AllClientTable from "./AllClientTable";
import RangePicker from "react-range-picker";
import "./style.css";
import swal from "sweetalert";
import dateformat from "date-format";
import { useReactToPrint } from "react-to-print";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const placeholder = ({ startDate, endDate }) => {
  if (!startDate) {
    return <div className="placeholder"> Select date and time range </div>;
  }
  return (
    <div className="placeholderWrap">
      <div className="placeholder">From - {startDate.toLocaleString()}</div>
      {endDate && (
        <div className="placeholder">To - {endDate.toLocaleString()}</div>
      )}
    </div>
  );
};

function ClientsManagement() {
  const [clients, setClients] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [inputNumber, setInputNumber] = useState("");
  const [inputNN, setInputNN] = useState("");
  const [inputFullName, setInputFullName] = useState("");
  const [inputAdresse, setInputAdresse] = useState("");
  const [checkinInput, setCheckInInput] = useState("");
  const [checkOutInput, setCheckOutInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [openedModal, setOpenedModal] = useState(0);
  const [selectedClient, setSelectedClient] = useState({});
  const [activity, setActivity] = useState(0);
  const [details, setDetails] = useState("");
  const toPrint = useRef();
  // const [roomprice, setRoomprice] = useState(0);
  // const [roomid, setRoomid] = useState("");
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState();

  const onDateChanges = (date, date2) => {
    setCheckInInput(date);
    setCheckOutInput(date2);
  };

  const handleToPrint = useReactToPrint({
    content: () => toPrint.current
  })

  function openModal(new_val) {
    // 0 -> create client, 1 -> deposit, 2 -> edit user, 3 -> view
    setOpenedModal(new_val);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const GetAllClients = () => {
    fetch("http://localhost:5000/api/v1/clients/get-all-client")
      .then((res) => res.json())
      .then((data) => {
        setClients(data?.data);
        // console.log(data);
      });
  };

  const DeleteClient = (client_id) => {
    setLoading(true);
    swal({
      title: "etes-vous sur?",
      text: "Une fois supprimé, vous ne pouvez plus recuperer ces données!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const res = await axios.post(
            "http://localhost:5000/api/v1/clients/delete-client",
            { client_id: client_id }
          );

          if (res?.data) {
            setLoading(false);
            GetAllClients();
            swal({
              title: "client supprimé!",
              text: "reussie!",
              icon: "success",
              button: "Ok",
            });
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        setLoading(false);
        swal("Client n'est pas supprimé! vous avez annulé");
      }
    });
  };

  const updateBalance = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        "http://localhost:5000/api/v1/clients/update-balance",
        {
          client_id: selectedClient._id,
          new_balance:
            parseFloat(selectedClient.balance) + parseFloat(activity),
          activities: [
            ...selectedClient.activities,
            {
              amount: parseFloat(activity),
              date: new Date(),
              motif: `${details} - montant - ${activity} --- solde compte -> ${
                parseFloat(selectedClient.balance) + parseFloat(activity)
              }`,
            },
          ],
        }
      );
      if (res?.data) {
        setLoading(false);
        closeModal();
        setDetails("");
        setActivity(0);
        GetAllClients();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const CreateClient = async () => {
    if(!selectedRoom){
      return alert("Selectionner le numero de la chambre du client!")
    }

    if(checkinInput === ""){
      return alert("Selectetionner checkin et checkout");
    }
    if(checkOutInput === ""){
      return alert("Selectetionner checkin et checkout");
    }
    setLoading(true);
    const date1 = new Date(checkOutInput);
    const date2 = new Date(checkinInput);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    try {
      const res = await axios.post(
        "http://localhost:5000/api/v1/clients/create-client",
        {
          email: inputEmail,
          number: inputNumber,
          adresse: inputAdresse,
          nn: inputNN,
          fullname: inputFullName,
          checkin: checkinInput,
          checkout: checkOutInput,
          roomid: selectedRoom?.roomId,
          roomprice: selectedRoom?.price,
          invoice: {
            days: diffDays,
            price: parseFloat(diffDays) * parseFloat(selectedRoom?.price)
          }
        }
      );
      if (res) {
        setLoading(false);
        setClients([...clients, res?.data?.data]);
        setInputAdresse("");
        setInputFullName("");
        setInputNumber("");
        setInputEmail("");
        setInputNN("");
        closeModal();
        setCheckInInput("");
        setCheckOutInput("");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    GetAllClients();
    fetch("http://localhost:5000/api/v1/products/rooms")
      .then((res) => res.json())
      .then((data) => setRooms(data?.data));
  }, []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button className="btn btn-primary" onClick={() => openModal(0)}>
          Ajouter Client
        </button>
      </div>
      <br />
      {/* <h1 style={{textAlign: 'center'}}>Client management</h1> */}
      <div>
        <div className="overflow-x-auto">
          <table className="table w-full xl:w-3/4 mx-auto">
            {/* head */}
            <thead className="bg-base-300">
              <tr className="">
                <th>N°</th>
                <th>Nom&PostNom</th>
                <th>Number</th>
                <th>Check-In</th>
                <th>Check-Out</th>
                <th>Chambre Facture</th>
                <th>balance</th>
                <th>actions</th>
                <th>voir</th>
              </tr>
            </thead>
            <tbody>
              {clients.map((single_client, index) => (
                <AllClientTable
                  key={single_client?._id}
                  client={single_client}
                  index={index}
                  setClients={setClients}
                  openModal={openModal}
                  setSelectedClient={setSelectedClient}
                  deleteClient={DeleteClient}
                ></AllClientTable>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            margin: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            className="btn btn-danger"
            style={{ backgroundColor: "crimson", color: "white" }}
            onClick={closeModal}
          >
            X
          </button>
        </div>
        {openedModal === 0 && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(1, auto)",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <input
              className="bg-[#e4e4e4ac] h-10 mb-2 w-full xl:w-[390px] pl-2 outline-none rounded"
              style={{ border: "1px solid gray" }}
              name="text"
              value={inputFullName}
              onChange={(e) => setInputFullName(e.target.value)}
              placeholder="Nom postnom et prenom"
            />
            <input
              className="bg-[#e4e4e4ac] h-10 mb-2 w-full xl:w-[390px] pl-2 outline-none rounded"
              style={{ border: "1px solid gray" }}
              name="email"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
              placeholder="Email"
            />
            <input
              className="bg-[#e4e4e4ac] h-10 mb-2 w-full xl:w-[390px] pl-2 outline-none rounded"
              style={{ border: "1px solid gray" }}
              type="number"
              value={inputNumber}
              onChange={(e) => setInputNumber(e.target.value)}
              placeholder="Numero de telephone"
            />
            <input
              className="bg-[#e4e4e4ac] h-10 mb-2 w-full xl:w-[390px] pl-2 outline-none rounded"
              style={{ border: "1px solid gray" }}
              type="text"
              value={inputAdresse}
              onChange={(e) => setInputAdresse(e.target.value)}
              placeholder="Adresse"
            />
            <input
              className="bg-[#e4e4e4ac] h-10 mb-2 w-full xl:w-[390px] pl-2 outline-none rounded"
              style={{ border: "1px solid gray" }}
              type="text"
              value={inputNN}
              onChange={(e) => setInputNN(e.target.value)}
              placeholder="Numero (carte d'identité)"
            />
            <select
              onChange={(e) => {
                setSelectedRoom(JSON.parse(e.target.value));
              }}
              // value={selectedRoom?.roomId}
              
              className="bg-[#e4e4e4ac] h-10 mb-2 w-full xl:w-[390px] pl-2 outline-none rounded"
              style={{ border: "1px solid gray"}}
            >
              <option value={null}></option>
              {rooms?.map((room_item, room_index) => {
                return (
                  <option key={room_index} value={JSON.stringify(room_item)}>
                    {room_item?.roomId}
                  </option>
                );
              })}
            </select>
            <RangePicker
              placeholderText="Check In / Check Out"
              onDateSelected={onDateChanges}
            />
            <br />
            <button
              disabled={loading}
              className="btn btn-success"
              onClick={CreateClient}
            >
              {loading ? "..." : "Ajouter"}
            </button>
          </div>
        )}
        {openedModal === 1 && (
          <div>
            <h1>DEPOSIT</h1>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(1, auto)",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <input
                className="bg-[#e4e4e4ac] h-10 mb-2 w-full xl:w-[390px] pl-2 outline-none rounded"
                style={{ border: "1px solid gray" }}
                type="text"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                placeholder="motif"
              />
              <input
                className="bg-[#e4e4e4ac] h-10 mb-2 w-full xl:w-[390px] pl-2 outline-none rounded"
                style={{ border: "1px solid gray" }}
                type="number"
                value={activity}
                onChange={(e) => setActivity(e.target.value)}
                placeholder="montant entré(sortie)"
              />
              <div style={{ dipslay: "flex", justifyContent: "center" }}>
                <button
                  disabled={loading}
                  className="btn btn-success"
                  onClick={updateBalance}
                >
                  {loading ? "..." : "Confirmer"}
                </button>
              </div>
            </div>
          </div>
        )}
        {openedModal === 2 && (
          <div>
            <h1>EDIT USER</h1>
          </div>
        )}
        {openedModal === 4 && (
          <div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <button onClick={()=>{handleToPrint();}} className="btn btn-primary" style={{background: "#181818", color: 'white', border: 'none'}}>Imprimer</button>
          </div>
        <div className="t-slip" ref={toPrint}>
          
          <div class="container">

            <div class="receipt_header">
            <h1>FACTURE <span>MAMAN KINDJA</span></h1>
            <h2>Address: 114 Av. Hippodrome, Nyalukemba, Bukavu<span>Tel: +243 973 952 327</span></h2>
            </div>
            
            <div class="receipt_body">

                <div class="date_time_con">
                    <div class="date">le {dateformat.asString("dd/MM/yyyy", new Date())}</div>
                    {/* <div class="time">11:13:06 AM</div> */}
                </div>
                <ul>
                    <li>Nom Client: {selectedClient?.fullname}</li>
                    <li>email: {selectedClient?.email}</li>
                    <li>Adresse: {selectedClient?.adresse}</li>
                    <li>numero: {selectedClient?.number}</li>

                </ul>

                <div class="items">
                    <table>
                
                        <thead>
                            <th>JOURS</th>
                            <th>PRIX</th>
                            <th>TOTAL</th>
                        </thead>
                
                        <tbody>
                          <tr>
                              <td>{selectedClient?.invoice?.days}</td>
                              <td>{parseFloat(selectedClient?.invoice?.price)/parseFloat(selectedClient?.invoice?.days)}$ / nuit</td>
                              <td>{selectedClient?.invoice?.price} $</td>
                          </tr>
                        </tbody>

                        <tfoot>
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td>$</td>
                            </tr>
                            <tr>
                                <td>Payé</td>
                                <td></td>
                                <td>CASH</td>
                            </tr>

                            <tr>
                                <td>Room</td>
                                <td></td>
                                <td>{selectedClient?.roomId || "..."}</td>
                            </tr>
                        </tfoot>

                    </table>
                </div>

            </div>


            <h3>Merci!</h3>

        </div>
        </div>
          </div>
        )}
        {openedModal === 3 && (
          <div>
            {/* <h2>Somme a payer: {}</h2>
            <h1 style={{ textAlign: "center", fontSize: "30px" }}>
              <b>FACTURE</b>
            </h1> */}
            <hr />
            <div>
              <ul
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  listStyleType: "circle",
                  margin: "20px",
                }}
              >
                {selectedClient?.activities?.map((comp, index) => {
                  return (
                    <li key={index}>
                      <span style={{ color: "gray", fontWeight: "400" }}>
                        {dateformat.asString(
                          "dd/MM/yyyy (hh:mm)",
                          new Date(comp?.date)
                        )}
                      </span>{" "}
                      - {comp?.motif} $
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default ClientsManagement;
