import React from 'react'

function InventoryTab() {
    return (
        <div>
            <h1 style={{textAlign: "center"}}>Inventaire</h1>
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                <div style={{width: "100%",}}>
                    <h1 style={{textAlign: 'center'}}>Hotel</h1>
                    <hr/>

                </div>
                <div style={{width: "100%",}}>
                    <h1 style={{textAlign: 'center'}}>Restaurant</h1>
                    <hr/>
                    
                </div>
            </div>
        </div>
    )
}

export default InventoryTab
