import React, { useEffect, useState } from "react";
import { IoFastFoodSharp } from "react-icons/io5";
import AllFoodsTable from "./AllFoodsTable";
import swal from "sweetalert";
import "./style.css";
import { FaX } from "react-icons/fa6";
import Modal from "react-modal";
import LOGO from "../../../../assets/logooo.png";
import dateformat from 'date-format';
import { TABLE_DATA } from "../../../constants/tables";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function RestaurantPage() {
  const [allFoods, setAllFoods] = useState([]);
  const [foods, setFoods] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [panier, setPanier] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});
  const [table, setTable] = useState("")
  const [waiters, setWaiters] = useState([])
  const [waiter, setWaiter] = useState("");
  const navigate = useNavigate();

  const GetAllClients = () => {
    fetch("http://localhost:5000/api/v1/clients/get-all-client")
      .then((res) => res.json())
      .then((data) => {
        setClients(data?.data);
        // console.log(data?.data);
      });
  };

  useEffect(() => {
    fetch(
      "http://localhost:5000/api/v1/users/get-users"
    )
      .then((res) => res.json())
      .then((data) => setWaiters(data?.data?.filter((item) => item?.role === "STUFF")));
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  /* 
        PANIER -> [
            {item, name, quantity, pu, total},
            {item, name, quantity, pu, total},
            {item, name, quantity, pu, total},
            {item, name, quantity, pu, total},
        ]
    */
  // console.log(orderData)

  const getTotal = () => {
    let total = 0;
    panier.map((element) => {
        total += parseFloat(element.price) * parseFloat(element.quantity)
    })
    return total
  }

  const EditQuantity = (product, new_quantity) => {
    if (new_quantity.toString() !== "") {
      // console.log("here")
      if (parseFloat(new_quantity) > 0) {
        let new_panier = [...panier];
        for (let i = 0; i < panier.length; i++) {
          if (panier[i].id === product.id) {
            new_panier[i].quantity = parseFloat(new_quantity);
          }
        }

        setPanier(new_panier);
        localStorage.setItem("cart", JSON.stringify(new_panier));
      }
    } else {
      let new_panier = [...panier];
      for (let i = 0; i < panier.length; i++) {
        if (panier[i].id === product.id) {
          new_panier[i].quantity = 0;
        }
      }

      setPanier(new_panier);
      localStorage.setItem("cart", JSON.stringify(new_panier));
    }
  };

  const removeToPanier = (product) => {
    let new_panier = panier.filter((item) => item.id !== product.id);
    setPanier(new_panier);
    localStorage.setItem("cart", JSON.stringify(new_panier));
  };

  const AddToPanier = (product) => {
    // console.log(product)
    let isthere = false;
    panier.map((item) => {
      if (item?.id === product?._id) {
        isthere = true;
      }
    });
    if (isthere === false) {
      setPanier([
        ...panier,
        {
          id: product?._id,
          name: product?.name,
          quantity: 1,
          price: product?.price,
        },
      ]);
      localStorage.setItem(
        "cart",
        JSON.stringify([
          ...panier,
          {
            id: product?._id,
            name: product?.name,
            quantity: 1,
            price: product?.price,
          },
        ])
      );
    } else {
      swal({
        title: "!produit deja ajouté dans le panier",
        text: "vous pouvez pas mettre un produit deux fois dans le panier!",
        icon: "warning",
        button: "Ok",
      });
    }
  };

  useEffect(() => {
    GetAllClients();
    fetch("http://localhost:5000/api/v1/foods/all-foods")
      .then((res) => res.json())
      .then((data) => {
        setAllFoods(data?.data);
        setFoods(data?.data);
      });

    if (JSON.parse(localStorage.getItem("cart"))) {
      setPanier(JSON.parse(localStorage.getItem("cart")));
    }
  }, []);

  const CreateBill = async () => {
    try{
        const res = await axios.post("http://localhost:5000/api/v1/bills/create-bill", {items: panier, waiter: waiter, total: getTotal(panier), roomId: "", table: table})
        if(res?.data){
            swal({
                title: "!Facture ajouté",
                text: "reussie",
                icon: "success",
                button: "Ok",
              });
            navigate("/admin/bills");
            JSON.parse(localStorage.setItem("cart", null));
        }
    }catch(err){
        alert("erreur! verifié votre connexion")
    }
  }

  const HandleChangeSearchKey = (e) => {
    setSearchKey(e.target.value);

    // console.log(e.target.value)
    let new_foods = allFoods.filter((value, index) => {
      // console.log(value?.name.toLowerCase())
      return value.name?.toLowerCase().includes(e.target.value.toLowerCase());
    });
    // console.log(new_foods)

    setFoods(new_foods);
  };
  return (
    <div className="bg-[#F1F5F9] bg-gradient-to-r from-stone-100 to-blue-50 calc-height">
      <p className=" border pl-12 text-xl text-black mb-8 font-bold bg-[#F8FAFC] h-14 flex items-center">
        <IoFastFoodSharp className="ml-5 mr-3 w-6 h-6" />
        Restaurant
      </p>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, auto)",
          justifyContent: "space-around",
        }}
      >
        <div className="overflow-x-auto">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              className="bg-[#e4e4e4ac] h-10 mb-2 w-full xl:w-[390px] pl-2 outline-none rounded p-2 m-2"
              style={{ borderColor: "gray" }}
              placeholder="recherche produit"
              type="text"
              value={searchKey}
              onChange={HandleChangeSearchKey}
            />
          </div>
          <table className="table w-full xl:w-3/4 mx-auto">
            {/* head */}
            <thead className="bg-base-300">
              <tr className="">
                <th></th>
                <th>Image</th>
                <th>Food ID</th>
                <th>Food Name</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {/* row 1 */}
              {foods.map((food, index) => (
                <AllFoodsTable
                  key={food?.foodId}
                  food={food}
                  index={index}
                  setAllFoods={setAllFoods}
                  isRestaurant={true}
                  AddToPanier={AddToPanier}
                ></AllFoodsTable>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <h2>PANIER</h2>
          <hr />
          <div>
          <label class="dropdown">
            <div class="dd-button">
            {client?.fullname ? client?.fullname : "Normal"}
            </div>

            <input type="checkbox" class="dd-input" id="test"/>
            {/* <label>Client</label> */}
            <ul class="dd-menu">
                <li onClick={() => setClient({})}>Normal</li>
            {
                clients.map((single, index) => {
                    return <li onClick={()=>setClient(single)} key={client._id}>{single?.fullname}</li>
                })
            }
            {/* <li class="divider"></li>
            <li>
                <a href="http://rane.io">Link to Rane.io</a>
            </li> */}
            </ul>

            </label>
          </div>
          <div>
          <div className="m-2" style={{display: "flex", alignItems: 'center'}}>
            <label className="m-2">Table</label>
          <select style={{minWidth: "70px", minHeight: "40px", border: "1px solid gray", borderRadius: "8px"}} onChange={((e) => setTable(e.target.value))}>
            <option value={table}>{table}</option>
            {
              TABLE_DATA.map((single_item) => {
                return <option key={single_item} value={single_item}>{single_item}</option>
              })
            }
          </select>
          </div>
          <div className="m-2" style={{display: "flex", alignItems: 'center'}}>
            <label className="m-2">Serveur</label>
          <select style={{minWidth: "70px", minHeight: "40px", border: "1px solid gray", borderRadius: "8px"}} onChange={((e) => setWaiter(e.target.value))}>
            <option value={waiter}>{waiter}</option>
            {
              waiters.map((single_item) => {
                return <option key={single_item._id} value={single_item?.name}>{single_item?.name}</option>
              })
            }
          </select>
          </div>
          </div>
          <hr/>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(1, auto)",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {panier.map((item) => {
              return (
                <div className="info-card mt-2">
                  <div className="info__title mr-2">{item?.name}</div>
                  <input
                    className="small-input mr-2"
                    value={item?.quantity}
                    onChange={(e) => {
                      EditQuantity(item, e.target.value);
                    }}
                    placeholder="quantite"
                    type="number"
                  />
                  <span
                    className="mr-2"
                    style={{
                      fontWeight: "bold",
                      borderRight: "1px solid gray",
                      paddingRight: "10px",
                    }}
                  >
                    P.U: {item?.price} $
                  </span>
                  <span className="mr-2" style={{ fontWeight: "bold" }}>
                    Total:{" "}
                    {parseFloat(item?.price) * parseFloat(item?.quantity)} $
                  </span>
                  <FaX
                    onClick={() => removeToPanier(item)}
                    className="info__close"
                    size={18}
                  />
                </div>
              );
            })}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={openModal} className="btn btn-success mt-2">
                {" "}
                Continuer
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="FACTURE"
        ariaHideApp={false}
      >
        <div className="t-slip">
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <button onClick={CreateBill} className="btn btn-primary" style={{background: "#181818", color: 'white', border: 'none'}}>Ajouter</button>
          </div>
          <div class="container">

            <div class="receipt_header">
            <h1>FACTURE <span>MAMAN KINDJA</span></h1>
            <h2>Address: 114 Av. Hippodrome, Nyalukemba, Bukavu<span>Tel: +243 973 952 327</span></h2>
            </div>
            
            <div class="receipt_body">

                <div class="date_time_con">
                    <div class="date">le {dateformat.asString("dd/MM/yyyy", new Date())}</div>
                    {/* <div class="time">11:13:06 AM</div> */}
                </div>
                <ul>
                    <li>TABLE: {table}</li>
                    <li>WAITER: {waiter}</li>
                </ul>

                <div class="items">
                    <table>
                
                        <thead>
                            <th>QTY</th>
                            <th>ITEM-PU</th>
                            <th>PU*QTY</th>
                        </thead>
                
                        <tbody>
                            {
                                panier.map((single_item) => {
                                    return <tr>
                                        <td>{single_item?.quantity}</td>
                                        <td>{single_item?.name}-{single_item?.price}$</td>
                                        <td>{parseFloat(single_item?.price) * parseFloat(single_item?.quantity)} $</td>
                                    </tr>
                                })
                            }
                        </tbody>

                        <tfoot>
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td>{getTotal(panier)}</td>
                            </tr>

                            <tr>
                                <td>Payé</td>
                                <td></td>
                                <td>CASH</td>
                            </tr>

                            <tr>
                                <td>Room</td>
                                <td></td>
                                <td>{client?.roomId || "..."}</td>
                            </tr>
                        </tfoot>

                    </table>
                </div>

            </div>


            <h3>Merci!</h3>

        </div>
        </div>
      </Modal>
    </div>
  );
}

export default RestaurantPage;
