import React from 'react';
import dateFormat from 'date-format'
import {FaEye} from 'react-icons/fa'


function AllClientTable({client, index, setClients, openModal, setSelectedClient, deleteClient}) {
    const {fullname, adresse, checkin, checkout, number, nn, email, balance, activities} = client;
    return (
        <tr>
            <th>{index+1}</th>
            <td>{fullname}</td>
            <td>{number}</td>
            <td>{dateFormat.asString("dd/MM/yyyy",new Date(checkin))}</td>
            <td>{dateFormat.asString("dd/MM/yyyy",new Date(checkout))}</td>
            <td>{client?.roomid} - {client?.invoice?.days} Jour - <span style={{color: "crimson", fontWeight: 'bold'}}>{client?.invoice?.price} $</span></td>
            <td>{balance}</td>
            <td>
                <button onClick={() => {setSelectedClient(client); openModal(1)}} style={{backgroundColor: "green", color: "white"}} className='btn'>depot</button>
                <button onClick={() => {setSelectedClient(client); openModal(2)}} style={{backgroundColor: "blue", color: "white"}} className="btn">modifié</button>
                <button onClick={() => {deleteClient(client._id);}}tton style={{backgroundColor: "crimson", color: "white"}} className="btn">supprimé</button>

            </td>
            <td>
                <button className='btn' onClick={() => {setSelectedClient(client); openModal(4)}} style={{cursor: "pointer"}} size={24} color="gray">Facture</button>
                <FaEye onClick={() => {setSelectedClient(client); openModal(3)}} style={{cursor: "pointer"}} size={24} color="gray"/>

            </td>

        </tr>
    )
}

export default AllClientTable
