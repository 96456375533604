import React from "react";
import swal from "sweetalert";

const ViewOrderTable = ({ order, index, setAllOrderData }) => {
  const { img, roomId, orderId, email, startDate, endDate, orderStatus, price } = order;

  const handleDeleteOrder = async (orderId) => {
    // alert(`Clicked on ${roomId}`)
    swal({
      title: "etes-vous sur?",
      text: "une fois supprimé; vous ne pouvez plus recuperer ce fichier",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const url = `http://localhost:5000/api/v1/orders/delete-room-order/${orderId}`;
        await fetch(url, {
          method: "DELETE",
        })
          .then((res) => res.json())
          .then((data) => console.log(data));
        swal("The order is Deleted", {
          icon: "success",
        });

        //this second fetched is use to refresh delete data
        await fetch(
          "http://localhost:5000/api/v1/orders/room-orders"
        )
          .then((res) => res.json())
          .then((data) => setAllOrderData(data?.data));
      } else {
        swal("Oder not deleted. You canceled it!");
      }
    });
  };

  const handleToggleOrder = async (new_state) => {
    // alert(`Clicked on ${roomId}`)
    swal({
      title: "etes-vous sur?",
      text: "voulez-vous modifier ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        // const new_formData = new FormData();
        // new_formData.append("order_id", orderId);
        // new_formData.append("new_state", new_state);
        const url = `http://localhost:5000/api/v1/orders/toggle-order-status`;
        await fetch(url, {
          method: "POST",
          body: JSON.stringify({order_id: orderId, new_state: new_state}),
          headers: {
            "content-type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => console.log(data));
        swal("The order is Modified", {
          icon: "success",
        });

        //this second fetched is use to refresh delete data
        await fetch(
          "http://localhost:5000/api/v1/orders/room-orders"
        )
          .then((res) => res.json())
          .then((data) => setAllOrderData(data?.data));
      } else {
        swal("Oder not modified. You canceled it!");
      }
    });
  };
  return (
    <tr>
      <th>{index + 1}</th>
      <td>
        <img className="w-28 h-20 rounded " src={img} alt="" />
      </td>
      <td>{roomId}</td>
      <td>{orderId}</td>
      <td>{email}</td>
      <td>{startDate}</td>
      <td>{endDate}</td>
      <td>{price}$</td>
      <td><span className={orderStatus === "CONFIRMER" ? "alert alert-success" : "alert alert-info"}>{orderStatus}</span></td>
      <td>
        {orderStatus === "EN ATTENTE" && <button
          onClick={() => {
            handleDeleteOrder(orderId);
          }}
          className="btn btn-error text-base-200 btn-sm btn-outline m-1"
        >
          Supprimer
        </button>}
        <button
          onClick={() => {
            handleToggleOrder(orderStatus === "EN ATTENTE" ? "CONFIRMER" : "EN ATTENTE")
          }}
          className={`btn ${orderStatus==="CONFIRMER" ? "btn-warning" :"btn-success"} text-base-200 btn-sm btn-outline m-1`}
        >
          {orderStatus === "CONFIRMER" ? "Annuler" : "Confirmer"}
        </button>
      </td>
    </tr>
  );
};

export default ViewOrderTable;
