import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import dateformat from 'date-format';
import { FaEye } from 'react-icons/fa6';
import Modal from "react-modal";
import ReactToPrint, { useReactToPrint } from 'react-to-print'



const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

function BillsPage() {
    const [bills, setBills] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedBill, setSelectedBill] = useState({});
    const toPrint = useRef();


    function openModal() {
        setIsOpen(true);
        // console.log(selectedBill);
      }
    
      function closeModal() {
        setIsOpen(false);
      }

    const FetchBills = async() => {
        setLoading(true);
        try{
            const res = await axios.get("http://localhost:5000/api/v1/bills/get-bills");
            if(res?.data){
                // console.log(res?.data);
                setBills(res?.data.data);
                setLoading(false);
            }
        }catch(err){
            alert("Erreur! Verifié votre connection");
            setLoading(false);
        }
    }

    const ConfirmBill = async (bill_id) => {
        setLoading(true);
        try{
            const res = await axios.post("http://localhost:5000/api/v1/bills/confirm-bill", {bill_id: bill_id, status: true});
            if(res?.data){
                FetchBills();
            }else{
                console.log("Erreur")
            }
        }catch(err){
            alert("Erreur! Verifier votre connection");
            setLoading(false);
        }
    }

    function getTotal(bill_items) {
        let total = 0;
        if(bill_items?.length > 0)
            bill_items.map((element) => {
                total += parseFloat(element.price) * parseFloat(element.quantity)
            })
        return total
      }
    
      const handleToPrint = useReactToPrint({
        content: () => toPrint.current
      })

    useEffect(() => {
        FetchBills();
    }, [])

    return (
            <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h3>Factures</h3>
      </div>
      <br />
      {/* <h1 style={{textAlign: 'center'}}>Client management</h1> */}
      <div>
        <div className="overflow-x-auto">
          <table className="table w-full xl:w-3/4 mx-auto">
            {/* head */}
            <thead className="bg-base-300">
              <tr className="">
                <th>N°</th>
                <th>ID</th>
                <th>Date</th>
                <th>Status</th>
                <th>waiter</th>
                <th>table</th>
                <th>total</th>
                <th>actions</th>
                <th>voir</th>
              </tr>
            </thead>
            <tbody>
              {bills.length > 0 && bills.map((single_bill, index) => (
                <tr key={index}>
                    <td>{index+1}</td>
                    <td>{single_bill?._id}</td>
                    <td>{dateformat.asString("dd/MM/yyyy" ,new Date(single_bill?.createdAt))}</td>
                    <td>{single_bill?.isConfirmed ? <span style={{color: "green",fontWeight: 'bold'}}>VALIDE</span> : <span style={{color: "crimson",fontWeight: 'bold'}}>EN ATTENTE</span>}</td>
                    <td>{single_bill?.waiter}</td>
                    <td>{single_bill?.table}</td>
                    <td style={{fontWeight: "bold"}}>{single_bill?.total} $</td>
                    <td>
                        <button onClick={() => ConfirmBill(single_bill?._id)} className="btn btn-success">CONFIRMER</button>
                    </td>
                    <td><FaEye onClick={() => {setSelectedBill(single_bill); openModal();}} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="FACTURE"
        ariaHideApp={false}
      >
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <button onClick={()=>{handleToPrint();}} className="btn btn-primary" style={{background: "#181818", color: 'white', border: 'none'}}>Imprimer</button>
          </div>
        <div className="t-slip" ref={toPrint}>
          
          <div class="container">

            <div class="receipt_header">
            <h1>FACTURE <span>MAMAN KINDJA</span></h1>
            <h2>Address: 114 Av. Hippodrome, Nyalukemba, Bukavu<span>Tel: +243 973 952 327</span></h2>
            </div>
            
            <div class="receipt_body">

                <div class="date_time_con">
                    <div class="date">le {dateformat.asString("dd/MM/yyyy", new Date(selectedBill?.createdAt))}</div>
                    {/* <div class="time">11:13:06 AM</div> */}
                </div>
                <ul>
                    <li>TABLE: {selectedBill?.table}</li>
                    <li>WAITER: {selectedBill?.waiter}</li>
                </ul>

                <div class="items">
                    <table>
                
                        <thead>
                            <th>QTY</th>
                            <th>ITEM-PU</th>
                            <th>PU*QTY</th>
                        </thead>
                
                        <tbody>
                            {
                                selectedBill?.items?.length > 0 && selectedBill.items.map((single_item) => {
                                    return <tr>
                                        <td>{single_item?.quantity}</td>
                                        <td>{single_item?.name}-{single_item?.price}$</td>
                                        <td>{parseFloat(single_item?.price) * parseFloat(single_item?.quantity)} $</td>
                                    </tr>
                                })
                            }
                        </tbody>

                        <tfoot>
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td>{getTotal(selectedBill.items)} $</td>
                            </tr>
                            <tr>
                                <td>Payé</td>
                                <td></td>
                                <td>CASH</td>
                            </tr>

                            <tr>
                                <td>Room</td>
                                <td></td>
                                <td>{selectedBill?.roomId || "..."}</td>
                            </tr>
                        </tfoot>

                    </table>
                </div>

            </div>


            <h3>Merci!</h3>

        </div>
        </div>
      </Modal>

        </div>
    )
}

export default BillsPage
