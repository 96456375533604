import React from "react";

const Events = () => {
  return (
    <div className="w-11/12 xl:w-[1100px] mx-auto mt-10 border-t">
      <p>
        <p className="text-xl xl:text-2xl font-semibold text-[#000080] uppercase mb-2 mt-3">
          Reunion et Evenements
        </p>
      </p>
      <div>
        <div className="flex flex-col xl:flex-row gap-6">
          <img
            className="xl: w-[550px] h-[330px] rounded"
            src="https://media.radissonhotels.net/image/radisson-blu-hotel-dhaka-water-garden/meeting-room/16256-113891-f63612804_3xl.jpg"
            alt=""
          />
          <p className="text-[#53565A] text-justify">
            Planifiez des réunions de conseil d'administration, des séminaires, des banquets ou de grands mariages en une seule
            de nos huit lieux événementiels polyvalents. Couvrant plus de 3 000 m²
            mètres, nos installations événementielles peuvent accueillir jusqu'à 1 100 invités dans un
            variété de paramètres. Bénéficiant de 990 mètres carrés, notre grande salle de bal
            offre beaucoup d'espace pour célébrer des mariages et des réceptions.
            Les équipements de réunion comprennent un service de restauration, une connexion Wi-Fi gratuite et les dernières nouveautés.
            équipement audiovisuel.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Events;
