import React from "react";
import swal from "sweetalert";
import "../Pages/style.css"
import { TABLE_DATA } from "../../../constants/tables";

const ManageUsersTable = ({ user, index, UpdateUsers }) => {
  const { _id, name, email, role, table } = user;

  const handleDeleteOrder = async (roomId) => {
    // alert(`Clicked on ${roomId}`)
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `http://localhost:5000/api/v1/orders/delete-room-order/${roomId}`;
        fetch(url, {
          method: "DELETE",
        })
          .then((res) => res.json())
          .then((data) => console.log(data));
        swal("The order is Deleted", {
          icon: "success",
        });

        //this second fetched is use to refresh delete data
        fetch(
          "http://localhost:5000/api/v1/users/get-users"
        )
          .then((res) => res.json())
          .then((data) => UpdateUsers(data?.data));
      } else {
        swal("Oder not deleted. You canceled it!");
      }
    });
  };

  const handleChangeRole = async (new_role) => {
    // alert(`Clicked on ${roomId}`)
    swal({
      title: "etes-vous sur?",
      text: "...",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log("Here")
        const url = `http://localhost:5000/api/v1/users/change-role`;
        fetch(url, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({"new_role": new_role, "user_email": email, "admin_email": "auctux@gmail.com"}),
        })
          .then((res) => res.json())
          .then((data) => console.log(data));
        swal("reussie", {
          icon: "success",
        });

        //this second fetched is use to refresh delete data
        fetch(
          "http://localhost:5000/api/v1/users/get-users"
        )
          .then((res) => res.json())
          .then((data) => {
            // console.log("Got here")
            // console.log(data);
            UpdateUsers(data?.data);

          });
          
      } else {
        swal("erreur!");
      }
    });
  };

  const handleChangeTable = async (new_table) => {
    // alert(`Clicked on ${roomId}`)
    swal({
      title: "etes-vous sur?",
      text: "...",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log("Here")
        const url = `http://localhost:5000/api/v1/users/change-user-table`;
        fetch(url, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({"new_table": new_table, "user_email": email, "admin_email": "auctux@gmail.com"}),
        })
          .then((res) => res.json())
          .then((data) => console.log(data));
        swal("reussie", {
          icon: "success",
        });

        //this second fetched is use to refresh delete data
        fetch(
          "http://localhost:5000/api/v1/users/get-users"
        )
          .then((res) => res.json())
          .then((data) => {
            // console.log("Got here")
            // console.log(data);
            UpdateUsers(data?.data);

          });
          
      } else {
        swal("erreur!");
      }
    });
  };
  return (
    <tr>
      <th>{index + 1}</th>
      <td>{_id}</td>
      <td>{name}</td>
      <td>{email}</td>
      <td>{role}</td>
      <td>
        {role !== "admin" && <><button onClick={() => handleChangeRole("MANAGER-HOTEL")} className="btn">Manager Hotel</button>
        <button onClick={() => handleChangeRole("MANAGER-RESTAURANT")} className="btn">Manager Restaurant</button>
        <button onClick={() => handleChangeRole("STUFF")} className="btn">Serviteur</button>
        <button onClick={() => handleChangeRole("user")} className="btn">utilisateur</button></>}
      </td>
      <td>
        {
          (role === 'STUFF') && <select onChange={((e) => handleChangeTable(e.target.value))}>
            <option value={table}>{table}</option>
            {
              TABLE_DATA.map((single_item) => {
                return <option key={single_item} value={single_item}>{single_item}</option>
              })
            }
          </select>
        }
      </td>
    </tr>
  );
};

export default ManageUsersTable;
