import React from "react";

const ResturantAndBar = () => {
  return (
    <div className="w-11/12 xl:w-[1100px] mx-auto mt-12 border-t">
      <p>
        <p className="text-xl xl:text-2xl font-semibold text-[#000080] uppercase mb-2 mt-3">
          Restaurants et bars
        </p>
      </p>
      <div>
        <div className="flex flex-col xl:flex-row gap-6">
          <img
            className="xl: w-[550px] h-[330px] rounded"
            src="https://media.radissonhotels.net/image/radisson-blu-hotel-dhaka-water-garden/restaurant/16256-113891-f63612968_3xl.jpg"
            alt=""
          />
          <p className="text-[#53565A] text-justify">
            Profitez de nos quatre restaurants et de nos deux bars lors de votre séjour à
            le Radisson Blu. Prenez un copieux petit-déjeuner au Jardin d'Eau
            Brasserie ou venez déjeuner et dîner autour d'un buffet international
            et des stations de cuisine en direct. Sublime offre une ambiance sophistiquée
            et des produits locaux du Bangladesh utilisés dans la cuisine raffinée. Si tu veux un
            Dans un cadre plus décontracté, savourez une cuisine d'inspiration asiatique au Spice & Rice.
            Envie d'un repas léger ? Chit Chat propose des sandwichs, des salades et
            quiches. Le soir, vous pourrez prendre un verre et vivre
            divertissement entre amis au Blaze Entertainment Lounge & Bar
            ou le bar à cigares.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResturantAndBar;
