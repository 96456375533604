export const TABLE_DATA = [
    "03",
    "07",
    "3C",
    "3A",
    "P2",
    "P4",
    "P5",
    "P6",
    "P7",
    "PX",
    "P10",
    "P15",
]
